'use client'

import { useEffect } from "react";

export default function useEventListener(event, listener) {
  useEffect(() => {
      const t = window
      const eventName = event
      t.addEventListener(eventName, listener);
      return () => t ? t.removeEventListener(event, listener) : null;
  });
};

export function emitEvent(eventName, data) {
  window.dispatchEvent(new CustomEvent(eventName, { detail: data }))  
}