'use client'

import { useEffect } from "react"
import Rollbar from "rollbar"
import useEventListener from "./EventListener"

/**
 * Adds Rollbar plugin to your site.
 *  
 * To log en error, use the following: 
 *   emitEvent("log_error", { message: "Error message.", detail: { ... more detail like params, exception etc. }})
 * 
 * To set the user information, use the following: 
 *   emitEvent("log_user_id", { id: user.id, email: user.email })
 * 
 * @param enabled Whether rollbar is enabled or not.
 * @param accessToken Client side access token.
 * @param env The deployment environment, like production or staging.
 * @param codeVersion The version of your code.
 * @param branch The branch of your code. Default is main.
 * @param domain The domain of your site. eg. https://www.example.com
 * @param userId The user ID , if any.
 * @returns 
 */
export default function RollbarPlugin(props) {

  let rollbar = null

  useEventListener("log_error", event => {
    if (rollbar) rollbar.error(event.detail.message, event.detail.data)
  })
  
  useEventListener("log_user_id", event => {
    if (rollbar) rollbar.configure({ payload: { person: event.detail } });
  })
  
  useEffect(() => {
    const rollbarConfig = {
      enabled: props.enabled,
      accessToken: props.accessToken,
      payload: {
        environment: props.env,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: props.codeVersion,
            guess_uncaught_frames: true
          }
        },
        server: {
          root: props.domain,
          branch: props.branch || 'main',
        },
        code_version: props.codeVersion,
        custom: {
          site: props.domain,
        },
        person: {
          id: props.userId
        }
      },
      captureUncaught: true,
      captureUnhandledRejections: true,
    }
  
    rollbar = new Rollbar(rollbarConfig)
    
  }, [])
  
  return <></>
  
}